import { CoreInterfaces } from "../Models";
import * as Constants from "../Constants";

export const QuestionsConfiguration: {
  [serviceCode in Constants.ServiceCode]: Array<CoreInterfaces.QuestionConfiguration>;
} = {
  GeneralInformation: [
    {
      Code: Constants.GeneralInformationQuestion.Q0001,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Readonly,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0002,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Readonly,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0019,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Readonly,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0020,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Readonly,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0021,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Readonly,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0003,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Autocomplete,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0004,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Autocomplete,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0005,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Autocomplete,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0006,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Autocomplete,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0017,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0038,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        // {
        //   Code: Constants.CounselingAssignment.Analysis,
        // },
        // {
        //   Code: Constants.CounselingAssignment.Economics,
        // },
        {
          Code: Constants.CounselingAssignment.ActiveBoardWork,
        },
        {
          Code: Constants.CounselingAssignment.BudgetingProcess,
        },
        {
          Code: Constants.CounselingAssignment.CFO_Coaching,
        },
        {
          Code: Constants.CounselingAssignment.OwnerDirectives,
        },
        {
          Code: Constants.CounselingAssignment.StartingSustainabilityWorkInSme,
        },
        {
          Code: Constants.CounselingAssignment.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0034,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.ContactPersonUserDetails,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0018,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.MultipleSelect,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0006,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: "Month.1",
        },
        {
          Code: "Month.2",
        },
        {
          Code: "Month.3",
        },
        {
          Code: "Month.4",
        },
        {
          Code: "Month.5",
        },
        {
          Code: "Month.6",
        },
        {
          Code: "Month.7",
        },
        {
          Code: "Month.8",
        },
        {
          Code: "Month.9",
        },
        {
          Code: "Month.10",
        },
        {
          Code: "Month.11",
        },
        {
          Code: "Month.12",
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0007,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0008,
      DefaultValue: Constants.VATDeclarationFrequency.MonthlyUnder,
      Options: [
        {
          Code: Constants.VATDeclarationFrequency.MonthlyOver,
        },
        {
          Code: Constants.VATDeclarationFrequency.MonthlyUnder,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.Yearly,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0009,
      DefaultValue: Constants.AccountingMethod.Accrual,
      Options: [
        {
          Code: Constants.AccountingMethod.CashFlow,
        },
        {
          Code: Constants.AccountingMethod.Accrual,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0010,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.CustomerBank.Handelsbanken,
        },
        {
          Code: Constants.CustomerBank.SEB,
        },
        {
          Code: Constants.CustomerBank.Nordea,
        },
        {
          Code: Constants.CustomerBank.Swedbank,
        },
        {
          Code: Constants.CustomerBank.Lansforsakringar,
        },
        {
          Code: Constants.CustomerBank.DanskeBank,
        },
        {
          Code: Constants.CustomerBank.Otherbanks,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0011,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0012,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0013,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0014,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0015,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0039,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0040,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0041,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0042,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.FeeExpression.PeriodFee,
        },
        {
          Code: Constants.FeeExpression.IntervalFee,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0022,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0043,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0023,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.FeeExpression.Amount,
        },
        {
          Code: Constants.FeeExpression.Interval,
        },
        {
          Code: Constants.FeeExpression.Table,
        },
        {
          Code: Constants.FeeExpression.OnCurrentAccount,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0024,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 8,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0025,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.RangeNumber,
      InputConstraints: {
        min: 0,
        maxLength: 8,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0029,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.FeeExpression.Current,
        },
        {
          Code: Constants.FeeExpression.Period,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0030,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Date,
      InputConstraints: {
        format: Constants.HelpfulConstants.DateFormatYearMonthAndDay,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0031,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.FullDate,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0032,
      DefaultValue: "Month.3",
      Options: [
        {
          Code: "Month.1",
        },
        {
          Code: "Month.2",
        },
        {
          Code: "Month.3",
        },
        {
          Code: "Month.4",
        },
        {
          Code: "Month.5",
        },
        {
          Code: "Month.6",
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0033,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0035,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.Declaration.INK1AndK10,
        },
        {
          Code: Constants.Declaration.K10,
        },
        {
          Code: Constants.Declaration.INK1,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0036,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.MultipleSelect,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0037,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.Q0016,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Date,
      InputConstraints: {
        format: Constants.HelpfulConstants.DateFormatYearAndMonth,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0009,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.Auditor.AuditCompany,
        },
        {
          Code: Constants.Auditor.Auditor,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0019,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.HighestDecision,
        },
        {
          Code: Constants.BodyAuditor.BoardDirector,
        },
        {
          Code: Constants.BodyAuditor.ConfederationMeeting,
        },
        {
          Code: Constants.BodyAuditor.AnnualMeeting,
        },
        {
          Code: Constants.BodyAuditor.GeneralMeeting,
        },
        {
          Code: Constants.BodyAuditor.ChurchCouncil,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0026,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.GeneralMeetingDecision,
        },
        {
          Code: Constants.BodyAuditor.GeneralMeetingAssociation,
        },
        {
          Code: Constants.BodyAuditor.AnnualMeeting,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0016,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.Years.OneYear,
        },
        {
          Code: Constants.Years.FourYear,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0025,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.Years.OneYear,
        },
        {
          Code: Constants.Years.TwoYear,
        },
        {
          Code: Constants.Years.ThreeYear,
        },
        {
          Code: Constants.Years.FourYear,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0017,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0010,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0021,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.RegulationsSupplimentary.Statutes,
        },
        {
          Code: Constants.RegulationsSupplimentary.FoundationAct,
        },
        {
          Code: Constants.RegulationsSupplimentary.FoundationActAndStatutes,
        },
        {
          Code: Constants.RegulationsSupplimentary.ChurchOrder,
        },
        {
          Code: Constants.RegulationsSupplimentary.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0021_1,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.RegulationsSupplimentary.Statutes,
        },
        {
          Code: Constants.RegulationsSupplimentary.FoundationAct,
        },
        {
          Code: Constants.RegulationsSupplimentary.FoundationActAndStatutes,
        },
        {
          Code: Constants.RegulationsSupplimentary.ChurchOrder,
        },
        {
          Code: Constants.RegulationsSupplimentary.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0022,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.Trustee,
        },
        {
          Code: Constants.BodyAuditor.BoardDirector,
        },
        {
          Code: Constants.BodyAuditor.ChurchCommittee,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0022_1,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.Trustee,
        },
        {
          Code: Constants.BodyAuditor.BoardDirector,
        },
        {
          Code: Constants.BodyAuditor.ChurchCommittee,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0023,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.Trustee,
        },
        {
          Code: Constants.BodyAuditor.BoardDirector,
        },
        {
          Code: Constants.BodyAuditor.ConfederationMeeting,
        },
        {
          Code: Constants.BodyAuditor.AnnualMeeting,
        },
        {
          Code: Constants.BodyAuditor.GeneralMeeting,
        },
        {
          Code: Constants.BodyAuditor.ChurchCouncil,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0023_1,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.BodyAuditor.Trustee,
        },
        {
          Code: Constants.BodyAuditor.BoardDirector,
        },
        {
          Code: Constants.BodyAuditor.ConfederationMeeting,
        },
        {
          Code: Constants.BodyAuditor.AnnualMeeting,
        },
        {
          Code: Constants.BodyAuditor.GeneralMeeting,
        },
        {
          Code: Constants.BodyAuditor.ChurchCouncil,
        },
        {
          Code: Constants.BodyAuditor.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0024,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.RegulationsSupplimentary.CharterFoundation,
        },
        {
          Code: Constants.RegulationsSupplimentary.StatutesSupplimentary,
        },
        {
          Code: Constants.RegulationsSupplimentary.Law,
        },
        {
          Code: Constants.RegulationsSupplimentary.ChurchOrderSupplimentary,
        },
        {
          Code: Constants.RegulationsSupplimentary.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0024_1,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.RegulationsSupplimentary.CharterFoundation,
        },
        {
          Code: Constants.RegulationsSupplimentary.StatutesSupplimentary,
        },
        {
          Code: Constants.RegulationsSupplimentary.Law,
        },
        {
          Code: Constants.RegulationsSupplimentary.ChurchOrderSupplimentary,
        },
        {
          Code: Constants.RegulationsSupplimentary.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0011,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0012,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0013,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.FormatFeeAuditLetter.Alternative1,
        },
        {
          Code: Constants.FormatFeeAuditLetter.Alternative2,
        },
        {
          Code: Constants.FormatFeeAuditLetter.Alternative3,
        },
        {
          Code: Constants.FormatFeeAuditLetter.Alternative4,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0014,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: {},
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0015,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Multiline,
      InputConstraints: {
        maxLength: 200,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
    {
      Code: Constants.GeneralInformationQuestion.QA0018,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        maxLength: 9,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Audit],
    },
  ],
  AccountsReceivable: [
    {
      Code: Constants.AccountReceivableQuestion.Q0101,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0102,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 3,
      },
      IsVisible: true,
      BusinessUnits: [
        Constants.BusinessUnit.Outsourcing,
        Constants.BusinessUnit.Audit,
      ],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0103,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0104,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0105,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0106,
      DefaultValue: Constants.Frequency.TwiceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0107,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.InvoicingTime.InAdvance,
        },
        {
          Code: Constants.InvoicingTime.InArears,
        },
        {
          Code: Constants.InvoicingTime.Ongoing,
        },
        {
          Code: Constants.InvoicingTime.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0108,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0109,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0110,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0111,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0112,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0113,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0114,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0115,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0116,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0117,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0118,
      DefaultValue: Constants.Frequency.OnceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0119,
      DefaultValue: Constants.Frequency.OnceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0122,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0120,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountReceivableQuestion.Q0121,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  AccountsPayable: [
    {
      Code: Constants.AccountPayableQuestion.Q0201,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0226,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0227,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0202,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 3,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0203,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0204,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0205,
      DefaultValue: Constants.AccountPayablePreSystem.MediusGo,
      Options: [
        {
          Code: Constants.AccountPayablePreSystem
            .FortnoxLeverantorsfakturaattest,
        },
        {
          Code: Constants.AccountPayablePreSystem.MediusGo,
        },
        {
          Code: Constants.AccountPayablePreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      //Q0014=N, other default values
      Code: Constants.AccountPayableQuestion.Q0205_1,
      DefaultValue: Constants.AccountPayablePreSystem.AnnatForsystem,
      Options: [
        {
          Code: Constants.AccountPayablePreSystem.MediusGo,
        },
        {
          Code: Constants.AccountPayablePreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0206,
      DefaultValue:
        Constants.AccountPayablePreSystem.FortnoxLeverantorsfakturaattest,
      Options: [
        {
          Code: Constants.AccountPayablePreSystem
            .FortnoxLeverantorsfakturaattest,
        },
        {
          Code: Constants.AccountPayablePreSystem.MediusGo,
        },
        {
          Code: Constants.AccountPayablePreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0206_1,
      DefaultValue: Constants.AccountPayablePreSystem.AnnatForsystem,
      Options: [
        {
          Code: Constants.AccountPayablePreSystem.MediusGo,
        },
        {
          Code: Constants.AccountPayablePreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0225,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0207,
      DefaultValue: "0",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 3,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0208,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0209,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0223,
      DefaultValue: Constants.Frequency.OnceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0210,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0213,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0214,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0215,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0216,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0217,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0218,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AccountPayableQuestion.Q0224,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0219,
      DefaultValue: Constants.Frequency.OnceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0220,
      DefaultValue: Constants.Frequency.OnceAWeek,
      Options: [
        {
          Code: Constants.Frequency.Daily,
        },
        {
          Code: Constants.Frequency.OnceAWeek,
        },
        {
          Code: Constants.Frequency.TwiceAWeek,
        },
        {
          Code: Constants.Frequency.EverySecondWeek,
        },
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
        {
          Code: Constants.Frequency.WhenNeeded,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0221,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AccountPayableQuestion.Q0222,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  CorporateCardManagement: [
    {
      Code: Constants.CorporateCardManagementQuestion.Q0301,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 3,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0302,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 4,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0303,
      DefaultValue: Constants.CorporateCardPreSystem.KvittoResa,
      Options: [
        {
          Code: Constants.CorporateCardPreSystem.KvittoResa,
        },
        {
          Code: Constants.CorporateCardPreSystem.Flex,
        },
        {
          Code: Constants.CorporateCardPreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0303_1,
      DefaultValue: Constants.CorporateCardPreSystem.AnnatForsystem,
      Options: [
        {
          Code: Constants.CorporateCardPreSystem.KvittoResa,
        },
        {
          Code: Constants.CorporateCardPreSystem.Flex,
        },
        {
          Code: Constants.CorporateCardPreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0313,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0304,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.CardProvider.FirstCard,
        },
        {
          Code: Constants.CardProvider.Handelsbanken,
        },
        {
          Code: Constants.CardProvider.SEBForetagskort,
        },
        {
          Code: Constants.CardProvider.DanskeBankForetagskort,
        },
        {
          Code: Constants.CardProvider.DNB,
        },
        {
          Code: Constants.CardProvider.Eurocard,
        },
        {
          Code: Constants.CardProvider.Mynt,
        },
        {
          Code: Constants.CardProvider.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0314,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.CardProvider.Eurocard,
        },
        {
          Code: Constants.CardProvider.SEBForetagskort,
        },
        {
          Code: Constants.CardProvider.FirstCard,
        },
        {
          Code: Constants.CardProvider.DanskeBankForetagskort,
        },
        {
          Code: Constants.CardProvider.Citibank,
        },
        {
          Code: Constants.CardProvider.Handelsbanken,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0305,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0306,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0307,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0308,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0309,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0310,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0311,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.CorporateCardManagementQuestion.Q0312,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  OtherAccountAndReconciliation: [
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0412,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsInfoTextVariat: true,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0409,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 4,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0401,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0402,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0403,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0404,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0413,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0414,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0415,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0416,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.CorporateCardPreSystem.KvittoResa,
        },
        {
          Code: Constants.CorporateCardPreSystem.AnnatForsystem,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0417,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0405,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0406,
      DefaultValue: Constants.Frequency.Monthly,
      Options: [
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0410,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0411,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.License,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0407,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.OtherAccountAndReconciliationQuestions.Q0408,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  PeriodReporting: [
    {
      Code: Constants.PeriodReportingQuestion.Q0509,
      DefaultValue: Constants.PeriodReportMethod.BudgetRapport,
      Options: [
        {
          Code: Constants.PeriodReportMethod.BudgetRapport,
        },
        {
          Code: Constants.PeriodReportMethod.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0509_1,
      DefaultValue: Constants.PeriodReportMethod.Other,
      Options: [
        {
          Code: Constants.PeriodReportMethod.BudgetRapport,
        },
        {
          Code: Constants.PeriodReportMethod.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0510,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 300,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0501,
      DefaultValue: Constants.Frequency.Monthly,
      Options: [
        {
          Code: Constants.Frequency.Monthly,
        },
        {
          Code: Constants.Frequency.Quarterly,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0502,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0503,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0504,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0506,
      DefaultValue: Constants.HelpfulConstants.NotApplicable,
      Options: [
        {
          Code: Constants.HelpfulConstants.NotApplicable,
        },
        {
          Code: Constants.YesNo.Yes,
        },
        {
          Code: Constants.YesNo.No,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0507,
      DefaultValue: Constants.HelpfulConstants.NotApplicable,
      Options: [
        {
          Code: Constants.HelpfulConstants.NotApplicable,
        },
        {
          Code: Constants.YesNo.Yes,
        },
        {
          Code: Constants.YesNo.No,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0508,
      DefaultValue: Constants.HelpfulConstants.NotApplicable,
      Options: [
        {
          Code: Constants.HelpfulConstants.NotApplicable,
        },
        {
          Code: Constants.YesNo.Yes,
        },
        {
          Code: Constants.YesNo.No,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0514,
      DefaultValue: Constants.HelpfulConstants.NotApplicable,
      Options: [
        {
          Code: Constants.HelpfulConstants.NotApplicable,
        },
        {
          Code: Constants.YesNo.Yes,
        },
        {
          Code: Constants.YesNo.No,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0513,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0511,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PeriodReportingQuestion.Q0512,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  InterimListedCompanies: [
    {
      Code: Constants.InterimListedCompaniesQuestions.Q0551,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.AccountingStandards.IFRS,
        },
        {
          Code: Constants.AccountingStandards.K3,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.InterimListedCompaniesQuestions.Q0552,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.ResponsibleForInterimReport.GT,
        },
        {
          Code: Constants.ResponsibleForInterimReport.GTAndClient,
        },
        {
          Code: Constants.ResponsibleForInterimReport.Client,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.InterimListedCompaniesQuestions.Q0553,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
  ],
  AnnualReporting: [
    {
      Code: Constants.AnnualReportingQuestions.Q0611,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0612,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0613,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0601,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0610,
      DefaultValue: "",
      Options: [
        {
          Code: Constants.PartOfAnnualAccounts.AnnualAccounts,
        },
        {
          Code: Constants.PartOfAnnualAccounts.AnnualReport,
        },
        {
          Code: Constants.PartOfAnnualAccounts.CompanyDeclaration,
        },
      ],
      InputType: Constants.QuestionInputType.Checkbox,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0607,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0604,
      DefaultValue: Constants.CompanyReport.K3,
      Options: [
        {
          Code: Constants.CompanyReport.K2,
        },
        {
          Code: Constants.CompanyReport.K3,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0605,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0614,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0615,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0616,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0617,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0618,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0619,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0620,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0639,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0640,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0641,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0621,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0622,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0623,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0624,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0625,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0626,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0627,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0628,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0642,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0629,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0630,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0631,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0632,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0633,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0634,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0635,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0636,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0637,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0638,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0602,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0603,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0606,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0608,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingQuestions.Q0609,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  AnnualReportingListedCompanies: [
    {
      Code: Constants.AnnualReportingListedCompaniesQuestions.Q0651,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.AccountingStandards.IFRS,
        },
        {
          Code: Constants.AccountingStandards.K3,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingListedCompaniesQuestions.Q0652,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.ResponsibleForInterimReport.GT,
        },
        {
          Code: Constants.ResponsibleForInterimReport.GTAndClient,
        },
        {
          Code: Constants.ResponsibleForInterimReport.Client,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.AnnualReportingListedCompaniesQuestions.Q0653,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
  ],
  PayrollAndExpenseAndTravelInvoiceManagement: [
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0701,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 1,
        maxLength: 5,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0702,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0703,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0704,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.CollectiveAgreement.NoAgreement,
        },
        {
          Code: Constants.CollectiveAgreement.Teknikavtalet,
        },
        {
          Code: Constants.CollectiveAgreement.Motorbranchavtalet,
        },
        {
          Code: Constants.CollectiveAgreement.Detaljhandelsavtalet,
        },
        {
          Code: Constants.CollectiveAgreement.Lager,
        },
        {
          Code: Constants.CollectiveAgreement.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0706,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0705,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0707,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0708,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0709,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsInfoTextVariat: true,
      IsVisible: false,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0710,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsInfoTextVariat: true,
      IsVisible: false,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0711,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: false,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0712,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: false,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0713,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: false,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0714,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0725,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0727,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0728,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0729,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0715,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0716,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0717,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0718,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0719,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0720,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0721,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0722,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0723,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 1,
        max: 31,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0724,
      DefaultValue: "25",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 1,
        max: 31,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0726,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0730,
      DefaultValue: Constants.PayslipDistribution.MyPaySlip,
      Options: [
        {
          Code: Constants.PayslipDistribution.MyPaySlip,
        },
        {
          Code: Constants.PayslipDistribution.Kivra,
        },
        {
          Code: Constants.PayslipDistribution.Flex,
        },
        {
          Code: Constants.PayslipDistribution.Fortnox,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0731,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0732,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0748,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 300,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0733,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0734,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0735,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0736,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0740,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0741,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0744,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0745,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0746,
      DefaultValue: Constants.YesNo.No,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0749,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Headline,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0750,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0751,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0752,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 1,
        max: 31,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0753,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0754,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Text,
      InputConstraints: {
        maxLength: 50,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0755,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0756,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0757,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Headline,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0758,
      DefaultValue: Constants.YesNo.Yes,
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0759,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.VacationYear.CurrentVacation,
        },
        {
          Code: Constants.VacationYear.PreviousVacation,
        },
        {
          Code: Constants.VacationYear.Other,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0760,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0761,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
        {
          Code: Constants.VacationPay.Continuously,
        },
        {
          Code: Constants.VacationPay.InConnectionWithVacation,
        },
        {
          Code: "Month.January",
        },
        {
          Code: "Month.February",
        },
        {
          Code: "Month.March",
        },
        {
          Code: "Month.April",
        },
        {
          Code: "Month.May",
        },
        {
          Code: "Month.June",
        },
        {
          Code: "Month.July",
        },
        {
          Code: "Month.August",
        },
        {
          Code: "Month.September",
        },
        {
          Code: "Month.October",
        },
        {
          Code: "Month.November",
        },
        {
          Code: "Month.December",
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0762,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0763,
      DefaultValue: Constants.HelpfulConstants.PleaseSelect,
      Options: [
        {
          Code: Constants.HelpfulConstants.PleaseSelect,
        },
      ],
      InputType: Constants.QuestionInputType.Select,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.PayrollAndExpenseAndTravelInvoiceManagementQuestions
        .Q0764,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
  ],
  AnnualPayrollRoutines: [],
  StartUpAccounting: [
    {
      Code: Constants.StartUpAccountingQuestions.Q0801,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        max: 50,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.StartUpAccountingQuestions.Q0802,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        max: 50,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.StartUpAccountingQuestions.Q0803,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        max: 50,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.StartUpAccountingQuestions.Q0804,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Numeric,
      InputConstraints: {
        min: 0,
        max: 50,
        maxLength: 2,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
    {
      Code: Constants.StartUpAccountingQuestions.Q0805,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.YesNo,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
  ],
  StartUpPayroll: [
    {
      Code: Constants.StartUpPayrollQuestions.Q0901,
      DefaultValue: "",
      Options: [],
      InputType: null,
      InputConstraints: null,
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
      IsInfoTextVariat: true,
    },
    {
      Code: Constants.StartUpPayrollQuestions.Q0902,
      DefaultValue: "",
      Options: [],
      InputType: Constants.QuestionInputType.Currency,
      InputConstraints: {
        min: 0,
        maxLength: 7,
      },
      IsVisible: true,
      BusinessUnits: [Constants.BusinessUnit.Outsourcing],
    },
  ],
  YearlyInternalDocumentationAndFormalities: [],
  EngagementCounseling: [],
  LimitedCompany: [],
  NonProfitOrg: [],
  EconomicAssociation: [],
};
